@import '~antd/dist/antd.css';

::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #000;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #808080;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #efe6ef;
}

input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(100%);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.ant-modal-mask {
  background-color: #00000099 !important;
  backdrop-filter: blur(5px);
}

/* Loader */
svg.jloader {
  width: 3.25em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}

.jloader circle {
  fill: none;
  stroke: #e9b44d;
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}

.ant-notification,
.ant-notification-notice {
  margin-right: 1em;
}
.ant-notification-notice-message {
  color: #fff;
  font-weight: bold;
}
.ant-btn > .ant-btn-loading-icon {
  display: inline-flex;
  position: relative;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title,
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #fff;
  /*  */
}

.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: rgba(255, 255, 255, 0.438);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #e9b44d;
}

.ant-steps-item-finish .ant-steps-item-icon,
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  border-color: #e9b44d;
  background-color: #e9b44d;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #e9b44d;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon
  .ant-steps-icon,
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  position: relative;
  top: -1px;
  font-weight: bold;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.custom-modal {
  width: 300px !important;
  max-width: 100%;
}

.custom-modal .ant-modal-content,
.success-modal .ant-modal-content {
  background-color: #221622;
  box-shadow: none;
}

.custom-modal .ant-modal-body {
  padding-bottom: 0px;
}

.custom-modal .ant-modal-content .ant-steps.ant-steps-vertical {
  width: 200px;
  margin: 0 auto;
}

.ant-pagination-item,
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background-color: #3e283e;
  border: 1px solid #3e283e;
}
.ant-pagination-item:hover,
.ant-pagination-prev .ant-pagination-item-link:hover,
.ant-pagination-next .ant-pagination-item-link:hover {
  border: 1px solid #935d93;
}

.ant-pagination-item a,
.ant-pagination-prev button,
.ant-pagination-next button {
  color: #fff;
}
.ant-pagination-item:hover a,
.ant-pagination-prev button:hover,
.ant-pagination-next button:hover {
  color: #fff !important;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: rgba(255, 255, 255, 0.25);
}

.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: #fff;
}
.ant-pagination-item-active {
  background-color: #935d93;
  border: 1px solid #935d93;
}
.ant-pagination-item-active:hover a {
  color: #fff;
}
.anticon {
  display: inline-flex;
}

.sell-nft-modal {
  max-width: 100%;
  width: 933px !important;
}

.custom-modal {
  width: 300px !important;
  max-width: 100%;
}
.success-modal {
  overflow: hidden;
}

.custom-modal .ant-modal-content .ant-steps.ant-steps-vertical {
  width: 200px;
  margin: 0 auto;
}

.sell-nft-modal {
  max-width: 100%;
  width: 933px !important;
}

.sell-nft-modal .ant-modal-content,
.success-modal .ant-modal-content {
  background-color: #000b16;
  box-shadow: none;
}
.wheel-result-modal {
  max-width: max-content !important;
  width: 90% !important;
}
.wheel-result-modal .ant-modal-content {
  background-image: linear-gradient(
    180deg,
    #2d1b33 0%,
    #692480 100%,
    #8b42a3 100%
  );

  border: 2px dashed #10b981;
}
.event-item-modal {
  max-width: 1236px !important;
  width: 100% !important;
  padding: 0;
}
.event-item-modal .ant-modal-content {
  background: none;
  box-shadow: none;
}

.sell-nft-modal .ant-modal-body {
  padding-bottom: 40px;
}
.sell-nft-modal .ant-modal-close-x {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sell-nft-modal .ant-modal-content .ant-steps.ant-steps-vertical {
  width: 200px;
  margin: 0 auto;
}

.idl-shop-modal {
  max-width: max-content !important;
  width: 90% !important;
}
.idl-shop-modal .ant-modal-content {
  border-radius: 10px;
  background-color: #3e283e;
  border: 1px solid #5b395b;
}

.select-primary {
  color: #fff;
}

.ant-select .ant-select-selector {
  padding: 0.75rem !important;
  width: 100%;
  height: max-content !important;
  background: rgba(255, 255, 255, 0.03) !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 0.25rem !important;
}

.select-primary.ant-select .ant-select-selection-item {
  height: 21.1px !important;
  line-height: inherit !important;
}

.select-primary.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector::after {
  line-height: 16px;
}

.select-primary.ant-select-show-search.ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector
  input {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.select-primary.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  box-shadow: none !important;
}

.select-primary .ant-select-arrow {
  color: #fff;
}

.super-large {
  height: 3rem;
}
.super-large .ant-btn {
  height: 3rem;
}

/* sdf */
.toggle-button .bar {
  position: absolute;
  width: 20px;
  height: 2px;
  border-radius: 1px;
  overflow: hidden;
}
.toggle-button .bar.bar-1 {
  top: calc(50% - 1px);
  left: calc(50% - 10px);
}
.toggle-button .bar.bar-2 {
  top: calc(50% - 1px - 6px);
  left: calc(50% - 10px);
}
.toggle-button .bar.bar-2 .inner {
  transition-delay: 0.7s;
}
.toggle-button .bar.bar-3 {
  top: calc(50% - 1px + 6px);
  left: calc(50% - 10px);
}
.toggle-button .bar.bar-3 .inner {
  transition-delay: 0.6s;
}
.toggle-button .bar.bar-4 {
  top: calc(50% - 1px);
  left: calc(50% - 10px);
  width: 10px;
  transform-origin: 1px 1px;
  transform: rotate(-45deg);
}
.toggle-button .bar.bar-4 .inner {
  transform: translateX(-100%);
  transition-delay: 0.5s;
}
.toggle-button .bar.bar-5 {
  top: calc(50% - 1px);
  left: calc(50% - 10px);
  width: 10px;
  transform-origin: 1px 1px;
  transform: rotate(45deg);
}
.toggle-button .bar.bar-5 .inner {
  transform: translateX(-100%);
  transition-delay: 0.4s;
}
.toggle-button .bar .inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background: #fff;
  transition: transform 0.2s;
  will-change: transform;
}
.is-visible {
  transform: translateX(0%);
}
.is-visible.toggle-button .bar.bar-2 .inner {
  transform: translateX(100%);
  transition-delay: 0.4s;
}
.is-visible.toggle-button .bar.bar-3 .inner {
  transform: translateX(100%);
  transition-delay: 0.5s;
}
.is-visible.toggle-button .bar.bar-4 .inner {
  transform: translateX(0%);
  transition-delay: 0.6s;
}
.is-visible.toggle-button .bar.bar-5 .inner {
  transform: translateX(0%);
  transition-delay: 0.7s;
}

.account-container {
  background-image: url('../assets/rofi-games/account-page/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.wdp-ribbon {
  display: inline-block;
  padding: 2px 15px;
  position: absolute;
  right: 0px;
  top: 10px;
  line-height: 24px;
  height: 28px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  border-radius: 0;
  text-shadow: none;
  font-weight: normal;
  background-color: red !important;
}

.wdp-ribbon-two:before,
.wdp-ribbon-two:before {
  display: inline-block;
  content: '';
  position: absolute;
  left: -14px;
  top: 0;
  border: 9px solid transparent;
  border-width: 14px 8px;
  border-right-color: red;
}
.wdp-ribbon-two:before {
  border-color: red;
  border-left-color: transparent !important;
  left: -9px;
}
.wdp-ribbon-four {
  right: 10px;
  top: 10px;
  line-height: 60px;
  height: 60px;
}
.wdp-ribbon-five {
  background: red;
  right: 10px;
  top: 10px;
  padding: 0px;
  height: 60px;
  width: 60px;
  text-align: center;
  vertical-align: middle;
  line-height: 60px;
  border-radius: 50%;
}
.wdp-ribbon-six {
  background: none !important;
  position: relative;
  box-sizing: border-box;
  position: absolute;
  width: 65px;
  height: 65px;
  top: 0px;
  right: 0px;
  padding: 0px;
  overflow: hidden;
}
.wdp-ribbon-inner-wrap {
  -ms-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
}
.wdp-ribbon-border {
  width: 0;
  height: 0;
  border-right: 65px solid red;
  border-bottom: 65px solid transparent;
  z-index: 12;
  position: relative;
  top: -20px;
}
.wdp-ribbon-text {
  font-size: 13px;
  font-weight: bold;
  line-height: 13px;
  position: absolute;
  z-index: 14;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 15px;
  left: -5px;
  width: 91px;
  text-align: center;
}
